<template>
    <div id="app">
        <div class="menu-lateral" :style="{ left: menuOpen ? '0' : '-100%' }" @click="closeMenu"> 
            <h6>FREEBET API REFERENCE</h6>
            <ul class="nav flex-column">
                <router-link class="nav-item nav-link" to="/games-api/information">Information</router-link>
                <router-link class="nav-item nav-link" to="/games-api/add">Add</router-link>
                <router-link class="nav-item nav-link" to="/games-api/cancel">Cancel</router-link>
            </ul>
            <h6>Round Information</h6>
            <ul class="nav flex-column">
                <router-link class="nav-item nav-link" to="/games-api/rounddetails">Round Details</router-link>
            </ul>
        </div>
        <div class="menu-lateral"> 
            <h6>FREEBET API REFERENCE</h6>
            <ul class="nav flex-column">
                <router-link class="nav-item nav-link" to="/games-api/information">Information</router-link>
                <router-link class="nav-item nav-link" to="/games-api/add">Add</router-link>
                <router-link class="nav-item nav-link" to="/games-api/cancel">Cancel</router-link>
            </ul>
            <h6>Round Information</h6>
            <ul class="nav flex-column">
                <router-link class="nav-item nav-link" to="/games-api/rounddetails">Round Details</router-link>
            </ul>
        </div>
        <div class="menu-mobile" @click="toggleMenu">
            <img src="../../assets/menu.png" alt="">
        </div>
    </div>
</template>

<script>
import '../../styles/MenuLateral.css'

export default {
  name: 'GamesIntegration',
  data() {
    return {
      menuOpen: false
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    closeMenu() {
      if (this.menuOpen) {
        this.menuOpen = false;
      }
    }
  }
};
</script>

<style scoped>
</style>
