import { createRouter, createWebHistory } from 'vue-router';

import GamesIntegration from './pages/GamesIntegration/GamesIntegration.vue';
import Overview from './pages/GamesIntegration/Overview.vue';
import Specifications from './pages/GamesIntegration/Specifications.vue'
import Changelog from './pages/GamesIntegration/Changelog.vue'
import Authentication from './pages/GamesIntegration/Authentication.vue'
import PlayerInfo from './pages/GamesIntegration/PlayerInfo.vue'
import Withdraw from './pages/GamesIntegration/Withdraw.vue'
import Deposit from './pages/GamesIntegration/Deposit.vue'
import Rollback from './pages/GamesIntegration/Rollback.vue'
import PlayerNotif from './pages/GamesIntegration/PlayerNotif.vue'
import RealityCheck from './pages/GamesIntegration/RealityCheck.vue'

import GamesAPI from './pages/GamesAPI/GamesAPI.vue'
import Information from './pages/GamesAPI/Information.vue'
import Add from './pages/GamesAPI/Add.vue'
import Cancel from './pages/GamesAPI/Cancel.vue'
import RoundDetails from './pages/GamesAPI/RoundDetails.vue'

import IfcAPI from './pages/IfcAPI/IfcAPI.vue'
import OverviewIfc from './pages/IfcAPI/Overview.vue'

const routes = [
  {
    path: '/',
    component: {
      created() {
        window.location.href = '/games-integration/overview';
      }
    }
  },
  // GamesIntegration
  {
    path: '/games-integration',
    component: GamesIntegration
  },
  {
    path: '/games-integration/overview',
    component: Overview
  },
  {
    path: '/games-integration/specifications',
    component: Specifications
  },
  {
    path: '/games-integration/changelog',
    component: Changelog
  },
  {
    path: '/games-integration/authentication',
    component: Authentication
  },
  {
    path: '/games-integration/playerinfo',
    component: PlayerInfo
  },
  {
    path: '/games-integration/withdraw',
    component: Withdraw
  },
  {
    path: '/games-integration/deposit',
    component: Deposit
  },
  {
    path: '/games-integration/rollback',
    component: Rollback
  },
  {
    path: '/games-integration/playernotif',
    component: PlayerNotif
  },
  {
    path: '/games-integration/realitycheck',
    component: RealityCheck
  },

  // GamesAPI
  {
    path: '/games-api',
    component: GamesAPI
  },
  {
    path: '/games-api/information',
    component: Information
  },
  {
    path: '/games-api/add',
    component: Add
  },
  {
    path: '/games-api/cancel',
    component: Cancel
  },
  {
    path: '/games-api/rounddetails',
    component: RoundDetails
  },

  {
    path: '/ifc-api',
    component: IfcAPI
  },
  {
    path: '/ifc-api/overview',
    component: OverviewIfc
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;