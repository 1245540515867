<template>
    <div id="app">
        <MenuLateral />
        <div class="content">
            <h2 class="auth-title">Reality check</h2>
            <p>This method sends the notification request about the action of the player in game. Available only for AVIATOR</p>

            <div class="reality-check-content">
                <p>To stop the game and show the reality check notification, the API should on <span class="blue">withdraw</span> method answer with the error code 412. In the window will be shown the message from the response.e.g { "code": 412, "message": "You have been playing for 60 minutes" }. In message should be information about the length of the time in game. Besides message, in the window following buttons will be presented: <span class="teal">stop</span> , <span class="teal">continue</span>& account history. When player presses the button stop or continue the game calls playerNotificationCallback method. When player presses the button account history, the game opens the link of the game history of players, which was presented in launch URL;</p>
            </div>

            <div class="box-menssage-red">
                <h5>Important!</h5>
                <p>Currently reality check is available only for AVIATOR</p>
            </div>

            <span class="space"></span>
        </div>
    </div>
</template>

<script>
import MenuLateral from './GamesIntegration.vue';
import '../../styles/RealityCheck.css'
export default {
    name: 'RealityCheck',
    components: {
        MenuLateral
    }
}
</script>

<style scoped></style>
