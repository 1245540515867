<template>
    <div id="app">
        <MenuLateral />
        <div class="content">
            <h2>CACTUSPLAY Games Integration API (1.0)</h2>
            <h3>Game Launch Process</h3>
            <p>To launch a game, online casino should generate launch link with appropriate parameters and open it in
                browser.</p>
            <div class="tabs-new">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="production-tab" data-toggle="tab" href="#home" role="tab"
                            aria-controls="home" aria-selected="true">Production</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="demo-tab" data-toggle="tab" href="#profile" role="tab"
                            aria-controls="profile" aria-selected="false">Demo</a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="production-tab">
                        <table class="table">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">Parameter</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>game</td>
                                    <td>string [game enum]</td>
                                    <td>Identifies specific game. (Game Identificator)</td>
                                </tr>
                                <tr>
                                    <td>user</td>
                                    <td>string</td>
                                    <td>ID of a player on the operators side</td>
                                </tr>
                                <tr>
                                    <td>token</td>
                                    <td>string</td>
                                    <td>The one time token is a unique string generated by the operator, used for launch and
                                        authentication</td>
                                </tr>
                                <tr>
                                    <td>currency</td>
                                    <td>string [currency enum]</td>
                                    <td>Game currency (currency code)</td>
                                </tr>
                                <tr>
                                    <td>operator</td>
                                    <td>string</td>
                                    <td>operator key name</td>
                                </tr>
                                <tr>
                                    <td>lang</td>
                                    <td>string [lang enum]</td>
                                    <td>Game interface language</td>
                                </tr>
                                <tr>
                                    <td>return_url</td>
                                    <td>string [pattern url]</td>
                                    <td>URL for return from game</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="box-menssage">
                            <h5>URL Structure</h5>
                            <code>https://{launch-url}/{game}?user={user}&amp;token={token}&amp;lang={lang}&amp;currency={currency}&amp;operator={operator}&amp;return_url={return_url}</code>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="demo-tab">
                        <table class="table">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">Parameter</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>game</td>
                                    <td>string [game enum]</td>
                                    <td>Identifies specific game. (Game Identificator)</td>
                                </tr>
                                <tr>
                                    <td>currency</td>
                                    <td>string [currency enum]</td>
                                    <td>Game currency (currency code)</td>
                                </tr>
                                <tr>
                                    <td>lang</td>
                                    <td>string [lang enum]</td>
                                    <td>Game interface language</td>
                                </tr>
                                <tr>
                                    <td>return_url</td>
                                    <td>string [pattern url]</td>
                                    <td>URL for return from game</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="box-menssage">
                            <h5>Real demo launch URL structure</h5>
                            <code>https://demo.play.bs2bet.com/launch/{game}?currency={currency}&amp;lang={lang}&amp;return_url={return_url}</code>
                        </div>
                        <div class="box-menssage">
                            <h5>Real demo URL example</h5>
                            <a href="https://demo.play.bs2bet.com/launch/aviator?currency=USD&lang=EN&return_url=https://demo.play.bs2bet.com/game-browser/">Click Here</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MenuLateral from './GamesIntegration.vue';
import '../../styles/Overview.css'
export default {
    name: 'OverviewPage',
    components: {
        MenuLateral
    }
}
</script>

<style scoped></style>
