<template>
    <div id="app">
        <div class="menu-lateral" id="navbarlateral" :style="{ left: menuOpen ? '0' : '-100%' }" @click="closeMenu">
            <h6>GETTING STARTED</h6>
            <ul class="nav flex-column">
                <router-link class="nav-item nav-link" to="/games-integration/overview">Overview</router-link>
                <router-link class="nav-item nav-link" to="/games-integration/specifications">Specifications</router-link>
                <router-link class="nav-item nav-link" to="/games-integration/changelog">Changelog</router-link>
            </ul>
            <h6>API REFERENCE</h6>
            <ul class="nav flex-column">
                <router-link class="nav-item nav-link" to="/games-integration/authentication">Authentication</router-link>
                <router-link class="nav-item nav-link" to="/games-integration/playerinfo">Player info</router-link>
                <router-link class="nav-item nav-link" to="/games-integration/withdraw">Withdraw</router-link>
                <router-link class="nav-item nav-link" to="/games-integration/deposit">Deposit</router-link>
                <router-link class="nav-item nav-link" to="/games-integration/rollback">Rollback</router-link>
                <router-link class="nav-item nav-link" to="/games-integration/playernotif">Player notification</router-link>
            </ul>
            <h6>INFO</h6>
            <ul class="nav flex-column">
                <router-link class="nav-item nav-link" to="/games-integration/realitycheck">Reality check</router-link>
            </ul>
        </div>
        <div class="menu-lateral" id="navbarlateral">
            <h6>GETTING STARTED</h6>
            <ul class="nav flex-column">
                <router-link class="nav-item nav-link" to="/games-integration/overview">Overview</router-link>
                <router-link class="nav-item nav-link" to="/games-integration/specifications">Specifications</router-link>
                <router-link class="nav-item nav-link" to="/games-integration/changelog">Changelog</router-link>
            </ul>
            <h6>API REFERENCE</h6>
            <ul class="nav flex-column">
                <router-link class="nav-item nav-link" to="/games-integration/authentication">Authentication</router-link>
                <router-link class="nav-item nav-link" to="/games-integration/playerinfo">Player info</router-link>
                <router-link class="nav-item nav-link" to="/games-integration/withdraw">Withdraw</router-link>
                <router-link class="nav-item nav-link" to="/games-integration/deposit">Deposit</router-link>
                <router-link class="nav-item nav-link" to="/games-integration/rollback">Rollback</router-link>
                <router-link class="nav-item nav-link" to="/games-integration/playernotif">Player notification</router-link>
            </ul>
            <h6>INFO</h6>
            <ul class="nav flex-column">
                <router-link class="nav-item nav-link" to="/games-integration/realitycheck">Reality check</router-link>
            </ul>
        </div>
        <div class="menu-mobile" @click="toggleMenu">
            <img src="../../assets/menu.png" alt="">
        </div>
    </div>
</template>

<script>

import '../../styles/MenuLateral.css'



export default {
  name: 'GamesIntegration',
  data() {
    return {
      menuOpen: false
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    closeMenu() {
      if (this.menuOpen) {
        this.menuOpen = false;
      }
    }
  }
};

</script>

<style scoped></style>
