<template>
    <div id="app">
        <MenuLateral />
        <div class="content">
            <h2 class="auth-title">Cancel Freebet</h2>
            <ul class="list-group list-new">
                <li class="list-group-item">Path: <span class="path">/freebets/cancel</span></li>
                <li class="list-group-item">Request Method: <span class="request">POST</span></li>
                <li class="list-group-item">Content-Type: <span class="type">application/json; charset=utf-8</span></li>
            </ul>

            <h2 class="request-title">Request</h2>
            <div class="tabs-new">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="parameters-tab" data-toggle="tab" href="#parameters" role="tab"
                            aria-controls="home" aria-selected="true">Parameters</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="sample-tab" data-toggle="tab" href="#sample" role="tab"
                            aria-controls="profile" aria-selected="false">Sample</a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="parameters" role="tabpanel" aria-labelledby="parameters-tab">
                        <table class="table currencies">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">Parameter</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>operator_freebet_id</td>
                                    <td>string</td>
                                    <td>Free bet id from operator</td>
                                </tr>
                                <tr>
                                    <td>operator_key</td>
                                    <td>string</td>
                                    <td>Operator key</td>
                                </tr>
                                <tr>
                                    <td>secret_token</td>
                                    <td>string</td>
                                    <td>Operator secret token</td>
                                </tr>
                                <tr>
                                    <td>provider</td>
                                    <td>string</td>
                                    <td>Game provider</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="tab-pane fade code" id="sample" role="tabpanel" aria-labelledby="sample-tab">
                        {
                       <div><span class="color-1">"user_token":</span> <span class="color-2"> "53dac65af9e735d08328a5aa4a009266d20a6103dde7d58ad3f5d20c3f31c420"</span>,</div>
                       <div><span class="color-1">"session_token":</span> <span class="color-2"> "j0CzDA2aq4rRZ03V4KBTvncDcgbBHEuoDsNEVBlkDc1UpfWkHjNgzHPoAzrBEpZd"</span>,</div>
                       <div><span class="color-1">"platform":</span> <span class="color-2"> "mobile"</span>,</div>
                       <div><span class="color-1">"currency":</span> <span class="color-2"> "USD"</span>,</div>
                        }
                    </div>
                </div>
            </div>

            <h2 class="request-title">Response</h2>
            <div class="tabs-new">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="parametersr-tab" data-toggle="tab" href="#parametersr" role="tab"
                            aria-controls="home" aria-selected="true">Parameters</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="successsample-tab" data-toggle="tab" href="#successsample" role="tab"
                            aria-controls="profile" aria-selected="false">Success sample</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="errorsample-tab" data-toggle="tab" href="#errorsample" role="tab"
                            aria-controls="profile" aria-selected="false">Error sample</a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="parametersr" role="tabpanel" aria-labelledby="parametersr-tab">
                        <table class="table currencies">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">Parameter</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>code</td>
                                    <td>integer [enum]</td>
                                    <td>Response code</td>
                                </tr>
                                <tr>
                                    <td>message</td>
                                    <td>string</td>
                                    <td>Response any logical message</td>
                                </tr>
                                <tr>
                                    <td>data</td>
                                    <td>object</td>
                                    <td>only needed when code 200</td>
                                </tr>
                                <tr>
                                    <td><span class="space">● user_id</span></td>
                                    <td>string</td>
                                    <td>ID of player on operator side</td>
                                </tr>
                                <tr>
                                    <td><span class="space">● username</span></td>
                                    <td>string</td>
                                    <td>Name of player</td>
                                </tr>
                                <tr>
                                    <td><span class="space">● balance</span></td>
                                    <td>integer</td>
                                    <td>Current balance of player in specified currency.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="tab-pane fade code" id="successsample" role="tabpanel" aria-labelledby="successsample-tab">
                        {
                       <div><span class="color-1">"code":</span> <span class="color-2">200</span>,</div>
                       <div><span class="color-1">"message":</span> <span class="color-2"> "ok"</span>,</div>
                       <div><span class="color-1">"data":</span> <span class="color-2"> "mobile"</span>,</div>
                       <div><span class="color-1">"currency":</span> <span class="color-2"> "USD"</span>,</div>
                        }
                    </div>

                    <div class="tab-pane fade code" id="errorsample" role="tabpanel" aria-labelledby="errorsample-tab">
                        {
                       <div><span class="color-1">"code":</span> <span class="color-2"> 404</span>,</div>
                       <div><span class="color-1">"message":</span> <span class="color-2"> "User token is invalid"</span>,</div>
                        }
                    </div>
                </div>
            </div>

            <div class="box-menssage-hellow">
                <h5>Amount/Balance example</h5>
                <p>Amount/Balance fiat is represented in units(1$ = 1000 unit) and always is an integer value. For example: with 5.32 USD and the value will be 5.32 * 10^3 = 5320</p>
                <p>Amount/Balance crypto is represented in units(1BTC = 100000000 unit) and always is an integer value. For example: with 0.0532 BTC and the value will be 0.0532 * 10^8 = 5320000</p>
            </div>

            <div class="box-menssage-teal">
                <h5>Possible codes</h5>
                <p>200 - Success</p>
                <p>401 - User token is invalid</p>
                <p>403 - User token is expired</p>
                <p>500 - Internal error</p> <br>
                <p>If code is 200 - operator should always return parameter data with information HTTP status code should always be 200</p>
            </div>

            <div class="box-menssage-red">
                <h5>Important!</h5>
                <p>If free bet already exists with operator_freebet_id create method doesn't create new freebet and returns current freebet information.</p>
            </div>

            <span class="space"></span>
        </div>
    </div>
</template>

<script>
import MenuLateral from './GamesAPI.vue';
//import '../../styles/Cancel.css'
export default {
    name: 'CancelPage',
    components: {
        MenuLateral
    }
}
</script>

<style scoped></style>
