<template>
    <div id="app">
        <MenuLateral />
        <div class="content">
            <h2 class="auth-title">CACTUSPLAY IFrame Communication API</h2>
            <h5>Interaction Diagram</h5>
            <img src="../../assets/diagram.png" alt="">

            <span class="space"></span>
        </div>
    </div>
</template>

<script>
import MenuLateral from './IfcAPI.vue';
//import '../../styles/Cancel.css'
export default {
    name: 'OverviewPage',
    components: {
        MenuLateral
    }
}
</script>

<style scoped>
img{
    width: 100%;
}
</style>
