<template>
    <div id="app">
        <MenuLateral />
        <div class="content">
            <h2>Changelog</h2>
            <h3>Version 1.0.3</h3>
            <p>Released on October 18, 2022</p>
            <div class="box-changelog">
                <p>Add turbo game "hotline".</p>
            </div>

            <h3>Version 1.0.2</h3>
            <p>Released on March 16, 2021</p>
            <div class="box-changelog">
                <p>Add new languages for Aviator: Azerbaijani, Spanish, Hindi, Portuguese (Brazil), Uzbek, Vietnamese, Chinese, Ukrainian, Japanese, Thai</p>
            </div>

            <h3>Version 1.0.1</h3>
            <p>Released on October 18, 2022</p>
            <div class="box-changelog">
                <p>Add turbo game "hotline".</p>
            </div>
            <span class="space"></span>
        </div>
    </div>
</template>

<script>
import MenuLateral from './GamesIntegration.vue';
import '../../styles/Changelog.css'
export default {
    name: 'ChangelogPage',
    components: {
        MenuLateral
    }
}
</script>

<style scoped></style>
