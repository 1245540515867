<template>
    <div id="app">
        <MenuLateral />
        <div class="content">

            <!-- Specifications -->
            <h2>Specifications</h2>
            <h3>Games</h3>
            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Provider key</th>
                        <th scope="col">Game identificator</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Dice</td>
                        <td><span class="crypto">cactusplay_crypto</span></td>
                        <td>dice</td>
                    </tr>
                    <tr>
                        <td>Goal</td>
                        <td><span class="crypto">cactusplay_crypto</span></td>
                        <td>goal</td>
                    </tr>
                    <tr>
                        <td>Plinko</td>
                        <td><span class="crypto">cactusplay_crypto</span></td>
                        <td>plinko</td>
                    </tr>
                    <tr>
                        <td>Mines</td>
                        <td><span class="mines">cactusplay_mines</span></td>
                        <td>mines</td>
                    </tr>
                    <tr>
                        <td>Double</td>
                        <td><span class="double">cactusplay_double</span></td>
                        <td>double</td>
                    </tr>
                    <tr>
                        <td>Tower</td>
                        <td><span class="crypto">cactusplay_crypto</span></td>
                        <td>tower</td>
                    </tr>
                    <tr>
                        <td>Wheel</td>
                        <td><span class="crypto">cactusplay_crypto</span></td>
                        <td>wheel</td>
                    </tr>
                </tbody>
            </table>

            <!-- Available Currencies -->
            <h2 class="h2-new">Available Currencies</h2>
            <div class="tabs-new">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="fiar-tab" data-toggle="tab" href="#fiat" role="tab"
                            aria-controls="home" aria-selected="true">fiat</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="crypto-tab" data-toggle="tab" href="#crypto" role="tab"
                            aria-controls="profile" aria-selected="false">crypto</a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="fiat" role="tabpanel" aria-labelledby="fiat-tab">
                        <table class="table currencies">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">Code</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Code</th>
                                    <th scope="col">Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>AED</td>
                                    <td>United Arab Emirates Dirham</td>
                                    <td>LBP</td>
                                    <td>Lebanese Pound</td>
                                </tr>
                                <tr>
                                    <td>AFN</td>
                                    <td>Afghanistan Afghani</td>
                                    <td>LEK</td>
                                    <td>Albanian lek</td>
                                </tr>
                                <tr>
                                    <td>AED</td>
                                    <td>United Arab Emirates Dirham</td>
                                    <td>LBP</td>
                                    <td>Lebanese Pound</td>
                                </tr>
                                <tr>
                                    <td>AFN</td>
                                    <td>Afghanistan Afghani</td>
                                    <td>LEK</td>
                                    <td>Albanian lek</td>
                                </tr>
                                <tr>
                                    <td>AED</td>
                                    <td>United Arab Emirates Dirham</td>
                                    <td>LBP</td>
                                    <td>Lebanese Pound</td>
                                </tr>
                                <tr>
                                    <td>AFN</td>
                                    <td>Afghanistan Afghani</td>
                                    <td>LEK</td>
                                    <td>Albanian lek</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="tab-pane fade" id="crypto" role="tabpanel" aria-labelledby="crypto-tab">
                        <table class="table currencies">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">Code</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Code</th>
                                    <th scope="col">Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>AED</td>
                                    <td>United Arab Emirates Dirham</td>
                                    <td>LBP</td>
                                    <td>Lebanese Pound</td>
                                </tr>
                                <tr>
                                    <td>AFN</td>
                                    <td>Afghanistan Afghani</td>
                                    <td>LEK</td>
                                    <td>Albanian lek</td>
                                </tr>
                                <tr>
                                    <td>AED</td>
                                    <td>United Arab Emirates Dirham</td>
                                    <td>LBP</td>
                                    <td>Lebanese Pound</td>
                                </tr>
                                <tr>
                                    <td>AFN</td>
                                    <td>Afghanistan Afghani</td>
                                    <td>LEK</td>
                                    <td>Albanian lek</td>
                                </tr>
                                <tr>
                                    <td>AED</td>
                                    <td>United Arab Emirates Dirham</td>
                                    <td>LBP</td>
                                    <td>Lebanese Pound</td>
                                </tr>
                                <tr>
                                    <td>AFN</td>
                                    <td>Afghanistan Afghani</td>
                                    <td>LEK</td>
                                    <td>Albanian lek</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="box-menssage-red">
                            <h5>Important!</h5>
                            <p>If cryptocurrency is enabled for the operator, promo and rain are not available.</p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Available Languages -->
            <h2 class="h2-new">Available Languages</h2>
            <div class="tabs-new">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="mines-tab" data-toggle="tab" href="#mines" role="tab"
                            aria-controls="home" aria-selected="true">mines</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="turbo-tab" data-toggle="tab" href="#turbo" role="tab"
                            aria-controls="profile" aria-selected="false">turbo</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="double-tab" data-toggle="tab" href="#double" role="tab"
                            aria-controls="profile" aria-selected="false">double</a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="mines" role="tabpanel" aria-labelledby="mines-tab">
                        <table class="table available">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">Lang code</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Lang code</th>
                                    <th scope="col">Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>az</td>
                                    <td>Azerbaijani</td>
                                    <td>bg</td>
                                    <td>Bulgarian</td>
                                </tr>
                                <tr>
                                    <td>bn</td>
                                    <td>Brunei Darussalam</td>
                                    <td>cz</td>
                                    <td>Czech</td>
                                </tr>
                                <tr>
                                    <td>de</td>
                                    <td>Germany</td>
                                    <td>el</td>
                                    <td>Greek</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="tab-pane fade" id="turbo" role="tabpanel" aria-labelledby="turbo-tab">
                        <table class="table available">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">Lang code</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Lang code</th>
                                    <th scope="col">Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>az</td>
                                    <td>Azerbaijani</td>
                                    <td>bg</td>
                                    <td>Bulgarian</td>
                                </tr>
                                <tr>
                                    <td>bn</td>
                                    <td>Brunei Darussalam</td>
                                    <td>cz</td>
                                    <td>Czech</td>
                                </tr>
                                <tr>
                                    <td>de</td>
                                    <td>Germany</td>
                                    <td>el</td>
                                    <td>Greek</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="tab-pane fade" id="double" role="tabpanel" aria-labelledby="double-tab">
                        <table class="table available">
                            <thead class="thead-dark">
                                <tr>
                                    <th scope="col">Lang code</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Lang code</th>
                                    <th scope="col">Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>az</td>
                                    <td>Azerbaijani</td>
                                    <td>bg</td>
                                    <td>Bulgarian</td>
                                </tr>
                                <tr>
                                    <td>bn</td>
                                    <td>Brunei Darussalam</td>
                                    <td>cz</td>
                                    <td>Czech</td>
                                </tr>
                                <tr>
                                    <td>de</td>
                                    <td>Germany</td>
                                    <td>el</td>
                                    <td>Greek</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <span class="space"></span>
        </div>
    </div>
</template>

<script>
import MenuLateral from './GamesIntegration.vue';
import '../../styles/Specifications.css'
export default {
    name: 'SpecificationsPage',
    components: {
        MenuLateral
    }
}
</script>

<style scoped></style>
