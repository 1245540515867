<template>
    <nav class="navbar navbar-expand-custom navbar-mainbg">
        <a class="navbar-brand navbar-logo" href="#">CACTUSPLAY</a>
        <button class="navbar-toggler" type="button" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <i class="fas fa-bars text-white"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <div class="hori-selector">
                    <div class="left"></div>
                    <div class="right"></div>
                </div>
                <li class="nav-item" id="default">
                    <router-link class="nav-link" to="/games-integration/overview"><i class="fa-solid fa-dice"></i>Games Integration API</router-link>
                </li>
                <li class="nav-item" id="games-api">
                    <router-link class="nav-link" to="/games-api/information"><i class="fa-solid fa-book"></i>Games API</router-link>
                </li>
                <li class="nav-item" id="ifc-api">
                    <router-link class="nav-link" to="/ifc-api/overview"><i class="fa-regular fa-file"></i>IFC API</router-link>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
import $ from "jquery";
import '../styles/header.css';

document.addEventListener("DOMContentLoaded", function() {
    var urlAtual = window.location.href;
    if(urlAtual.includes("games-integration/overview")){
        $('#default').addClass('active');
    }else if(urlAtual.includes("games-api/information")){
        $('#games-api').addClass('active');
    }else if(urlAtual.includes("ifc-api/overview")){
        $('#ifc-api').addClass('active');
    }else{
        $('#default').addClass('active');
    }
});


export default {
    name: 'AppHeader',
    mounted: function () {
        function test() {
            var tabsNewAnim = $('#navbarSupportedContent');
            var activeItemNewAnim = tabsNewAnim.find('.active');
            var activeWidthNewAnimHeight = activeItemNewAnim.innerHeight();
            var activeWidthNewAnimWidth = activeItemNewAnim.innerWidth();
            var itemPosNewAnimTop = activeItemNewAnim.position();
            var itemPosNewAnimLeft = activeItemNewAnim.position();
            $(".hori-selector").css({
                "top": itemPosNewAnimTop.top + "px",
                "left": itemPosNewAnimLeft.left + "px",
                "height": activeWidthNewAnimHeight + "px",
                "width": activeWidthNewAnimWidth + "px"
            });
            $("#navbarSupportedContent").on("click", "li", function () {
                $('#navbarSupportedContent ul li').removeClass("active");
                $(this).addClass('active');
                var activeWidthNewAnimHeight = $(this).innerHeight();
                var activeWidthNewAnimWidth = $(this).innerWidth();
                var itemPosNewAnimTop = $(this).position();
                var itemPosNewAnimLeft = $(this).position();
                $(".hori-selector").css({
                    "top": itemPosNewAnimTop.top + "px",
                    "left": itemPosNewAnimLeft.left + "px",
                    "height": activeWidthNewAnimHeight + "px",
                    "width": activeWidthNewAnimWidth + "px"
                });
            });
        }
        $(document).ready(function () {
            setTimeout(function () { test(); });
        });
        $(window).on('resize', function () {
            setTimeout(function () { test(); }, 500);
        });
        $(".navbar-toggler").click(function () {
            $(".navbar-collapse").slideToggle(300);
            setTimeout(function () { test(); });
        });
    }

}

</script>

<style scoped>
</style>
