import { createApp } from 'vue';
import 'bootstrap/dist/js/bootstrap.js';
import App from './App.vue';
import router from './router';

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';

// No need to configure Vue production tip in Vue 3
const app = createApp(App);

//Import router links
app.use(router);

// Add jQuery to the app instance
import jQuery from 'jquery';
global.jQuery = jQuery;
app.config.globalProperties.$ = jQuery;

// Mount the app
app.mount('#app');